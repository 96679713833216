






import Vue from 'vue';
import { mapState } from 'vuex';

import TestingCentersDay from '@/components/TestingCenters/TestingCenterDay.vue';

export default Vue.extend({
  components: {
    TestingCentersDay,
  },
  computed: mapState(['appointmentDates']),
  mounted() {
    this.$store.dispatch(
      'getLabDaysById',
      this.$store.state.currentTestingCenter.id
    );
  },
});
