























import Vue from 'vue';
import { mapState } from 'vuex';

import { formatDate } from '@/helpers/dates';

import { mmddyyyyToDate } from '@/helpers/dates';
import { AppointmentDate } from '@/api/models/AppointmentDates';

export default Vue.extend({
  name: 'TestingCenterDayCard',
  props: {
    date: {
      type: Object as () => AppointmentDate,
    },
  },
  computed: {
    ...mapState(['locale']),
    hoursWithTime: function () {
      const template = this.$t('HoursWithTime') as string;
      const startTime = formatDate(
        new Date(
          0,
          0,
          0,
          this.hours(this.date.hours.start),
          this.minutes(this.date.hours.start)
        ),
        'hh:mm a',
        this.locale
      );
      const endTime = formatDate(
        new Date(
          0,
          0,
          0,
          this.hours(this.date.hours.end),
          this.minutes(this.date.hours.end)
        ),
        'hh:mm a',
        this.locale
      );
      const withStart = template.replace('%@', startTime);
      const withStartAndEnd = withStart.replace('%@', endTime);
      return withStartAndEnd;
    },
  },
  methods: {
    formatDate,
    mmddyyyyToDate,
    hours(unformattedTime: string): number {
      const timeSections = unformattedTime.split(':');
      return parseInt(timeSections[0]);
    },
    minutes(unformattedTime: string): number {
      const timeSections = unformattedTime.split(':');
      return parseInt(timeSections[1]);
    },
    setAppointmentDate(): void {
      if (this.date.available) {
        this.$store.dispatch('setCurrentAppointmentDate', this.date);
        this.$router.push('time');
      }
    },
  },
});
