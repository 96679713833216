




































import Vue from 'vue';
import { mapState } from 'vuex';

import TestingCenterDayCard from '@/components/TestingCenters/TestingCenterDayCard.vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';

export default Vue.extend({
  components: {
    TestingCenterDayCard,
    LoadingIndicator,
  },
  props: {
    dates: {
      type: Array,
    },
  },
  computed: {
    ...mapState(['isLoading', 'currentTestingCenter']),
    isDatesEmpty: function () {
      // If API call has finished and the dates object is empty
      // show retry button
      return (
        !this.isLoading &&
        (this.dates === null || !Object.keys(this.dates).length)
      );
    },
  },
  methods: {
    retry: function () {
      this.$store.dispatch(
        'getLabDaysById',
        this.$store.state.currentTestingCenter.id
      );
    },
  },
});
